<template>
    <CardComponent
        :title="title"
        :icon="icon"
        :tip="tip"
        link="/finance/accounting/daily/list"
        :filter="true"
        :options="options"
        width=".8rem"
        @handleFilter="handleFilter"
        class="finance-card"
    >
        <section>
            <div class="gmv">
                <p class="title">退款后GMV</p>
                <p>
                    <span class="unit">￥</span>
                    <span v-if="data.refundAfterGmvValue" class="price"></span>
                    <span v-else class="price"></span>
                </p>
            </div>
            <div class="other">
                <div>
                    <p class="title">店铺边际贡献</p>
                    <span class="unit">￥</span>
                    <span class="price">{{ data.value1Format }}</span>
                </div>
                <div>
                    <p class="title">费率</p>
                    <span
                        :class="{
                            price: true,
                            red: data.shopContributionMarginRealityRate < 0
                        }"
                        >{{ data.rate1Format }}</span
                    >
                </div>
            </div>
            <div class="other">
                <div>
                    <p class="title">剩余盈余</p>
                    <span class="unit">￥</span>
                    <span class="price">{{ data.value2Foramt }}</span>
                </div>
                <div>
                    <p class="title">费率</p>
                    <span :class="{ price: true, red: data.surplusRate < 0 }">{{
                        data.rate2Format
                    }}</span>
                </div>
            </div>
        </section>
    </CardComponent>
</template>

<script>
import CardComponent from '../components/card'
import { moneyFilter } from '@/utils/index'
export default {
    name: '',
    components: {
        CardComponent
    },
    props: {
        groupId: {
            type: Number,
            default: null
        }
    },
    watch: {
        groupId() {
            this.getData(this.weekValue)
        }
    },
    data() {
        return {
            title: '财务经营',
            tip: '财务经营',
            icon: 'iconfont icon-caiwuyunying',
            options: [
                {
                    label: '上周',
                    value: 1
                },
                {
                    label: '本周',
                    value: 0
                }
            ],
            currentUser: {},
            group: {},
            data: {},
            weekValue: 1
        }
    },
    mounted() {
        this.currentUser = this.$axios.getCurrentUser()
        this.getData(this.weekValue)
    },
    methods: {
        moneyFilter,
        handleFilter(val) {
            this.weekValue = val
            this.getData(val)
        },
        setJumpNumber(startNum, targetNum, time, selector) {
            const that = this
            console.log(that)
            const dom = document.getElementsByClassName(selector)[0]
            if (targetNum) {
                // console.log(dom);
                targetNum = targetNum.toFixed(2)
                let originNum = startNum
                let stepNum = 0
                let timeNum = time
                dom.innerHTML = startNum

                const timeId = setInterval(() => {
                    if (originNum < targetNum) {
                        timeNum -= 0.001
                        const strNum = originNum.toString()
                        // 数字比较少的时候直接用 + 1; 数字很大直接 +1 要很久才能调到最对应的数字，所有后三位数随机跳动的方式进行模拟生成
                        if (targetNum.toString().length < 6) {
                            stepNum += 1 // 这样才可以实现越跳越快的效果
                            originNum = originNum + stepNum
                            dom.innerHTML = originNum
                        } else {
                            stepNum += 1000 // 这样才可以实现越跳越快的效果
                            originNum = originNum + stepNum
                            dom.innerHTML =
                                strNum.substr(0, strNum.length - 3) +
                                Math.floor(Math.random() * 10) +
                                Math.floor(Math.random() * 10) +
                                Math.floor(Math.random() * 10)
                        }
                    } else {
                        dom.innerHTML = that.moneyFilter(targetNum, true)
                        clearInterval(timeId)
                    }
                }, timeNum)
            } else {
                dom.innerHTML = '0.00'
            }
        },
        async getData(val) {
            this.data = {}
            const dom = document.getElementsByClassName('price')[0]
            dom.innerHTML = '0.00'
            const res = await this.$axios.fetch(
                'accountingServer',
                '/dws/list-financial-operation',
                {
                    tenantId: this.currentUser.tenantId,
                    groupId: this.groupId,
                    num: val
                }
            )
            this.data = res.data ? res.data : {}
            // 店铺边际贡献
            this.data.value1Format = this.moneyFilter(
                this.data.shopContributionMarginRealityValue,
                true
            )
            // 店铺边际贡献 费率
            this.data.rate1Format = this.data.shopContributionMarginRealityRate
                ? this.data.shopContributionMarginRealityRate.toFixed(2) + '%'
                : '0:00%'
            // 剩余盈余
            this.data.value2Foramt = this.moneyFilter(
                this.data.surplusValue,
                true
            )
            // 剩余盈余 费率
            this.data.rate2Format = this.data.surplusRate
                ? this.data.surplusRate.toFixed(2) + '%'
                : '0:00%'
            this.setJumpNumber(0, this.data.refundAfterGmvValue, 5, 'price')
        }
    }
}
</script>
<style lang="less" scoped>
.finance-card {
  grid-row-end: span 2;
}
section {
    margin-top: 0.16rem;
    flex: 1;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .title {
        height: 0.24rem;
        font-family: PingFangSC-Regular;
        font-size: 0.16rem;
        color: #a7b6c3;
        line-height: 0.24rem;
        font-weight: 400;
    }
    .gmv {
        height: 0.96rem;
        color: #54636f;
        margin-bottom: 0.36rem;
        .unit {
            font-size: 0.26rem;
            // font-size: 0.4rem;
        }
        .price {
            height: 0.7rem;
            font-family: SanFranciscoDisplay-Medium;
            // font-size: 0.5rem;
            font-size: 0.44rem;
            line-height: 0.7rem;
            font-weight: 500;
        }
    }
    .other {
        display: flex;
        // justify-content: space-between;
        color: #54636f;
        flex: 1;
        .unit {
            // font-size: 0.25rem;
            font-size: 0.2rem;
        }
        .price {
            height: 0.44rem;
            font-family: SanFranciscoDisplay-Medium;
            font-size: 0.24rem;
            line-height: 0.44rem;
            font-weight: 500;
        }
        .price.green {
            font-size: 0.24rem;
            color: #39a36b;
        }
        .price.red {
            font-size: 0.24rem;
            color: #df5a68;
        }

        div:nth-child(1) {
            width: 50%;
        }
        div:nth-child(2) {
            padding-left: 25px;
            flex: 1;
        }
    }
}
</style>
